<template>
  <div id="header" class="header">
    <!-- 移动端的左侧菜单栏 -->
    <div class="move-tab" style="display: none;">
      <ul :class="{nav_menu:true,expand:isActive}">
        <li class="nav_menu-item">
          <a href='/' class="item-title">首页</a>
        </li>
        <li class="nav_menu-item" @click="expandTab">
          <router-link @click="toggleActive" :to="{path:'/about',query:{id:abouturl}}">协会概括</router-link>
          <span class="icon-expand">
            <svg t="1718260283357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="2314" width="20" height="20">
              <path
                d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                fill="#ffffff" p-id="2315" />
            </svg>
          </span>
          <ul :class="{'second-tab':true,expand_sec:isSecActive}">
            <li v-for="(sonItem,index) in aboutList" :key="index" @click="toggleActive">
              <router-link :to="{path:'/about',query:{id:sonItem.id}}">{{sonItem.title}}</router-link>
            </li>
          </ul>
        </li>
        <li class="nav_menu-item" @click="expandTab">
          <router-link  :to="{path:'/trends',query:{id:1}}"">协会动态</router-link>
          <span class="icon-expand">
            <svg t="1718260283357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="2314" width="20" height="20">
              <path
                d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                fill="#ffffff" p-id="2315" />
            </svg>
          </span>
          <ul :class="{'second-tab':true,expand_sec:isSecActive}">
            <li v-for="(sonItem,index) in newsList" :key="index"  @click="toggleActive">
              <router-link :to="{path:'/trends',query:{id:sonItem.id}}">{{sonItem.title}}</router-link>
            </li>
        </ul>
        </li>

        <li class="nav_menu-item" @click="expandTab" v-for="(item,index) in topList" :key="index">
          <a v-if="item.parent_id!==28&&item.parent_id!==9" :href='item.url' class="item-title">{{item.parentName}}</a>
          <a v-if="item.parent_id==28" href='/trends/new?id=3' class="item-title">{{item.parentName}}</a>
          <a v-if="item.parent_id==9" href='/about/business?id=5' class="item-title">{{item.parentName}}</a>
          <span class="icon-expand" v-if="item.parent_id!==28&&item.parent_id!==9">
            <svg t="1718260283357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="2314" width="20" height="20">
              <path
                d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                fill="#ffffff" p-id="2315" />
            </svg>
          </span>
          <ul :class="{'second-tab':true,expand_sec:isSecActive}"
          v-if="item.parent_id!==28&&item.parent_id!==9">
            <li v-for="(sonItem, sonIndex) in item.children" :key="sonIndex"  @click="toggleActive">
              <router-link  :to="{path:'/article',query:{id:sonItem.children_id}}">{{ sonItem.childrenName }}</router-link>
            </li>
          </ul>
          <!-- <ul class="second-tab" v-if="item.id==3">
            <li v-for="(item, index) in firstLevel.slice(0,4)" :key="item.id"  @click="toggleActive">
              <router-link :to="{ name: 'products', query: { category_id: item.id } }">{{ item.name }}</router-link>
            </li>
          </ul> -->
        </li>
        <li class="nav_menu-item">
          <a href='/trendsDetail?id=43' class="item-title">协会全媒体</a>
        </li>
        <li class="nav_menu-item">
          <a href='http://www.szgh.cn/' class="item-title">数字公会</a>
        </li>

        <!-- 控制隐藏的按钮 -->
        <div id="toggleMenu" :class="{dele:isDelete}" @click="toggleActive">
          <svg t="1718419459346" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4291" width="30" height="30">
            <path
              d="M964.794062 100.92562 923.057495 59.190077 511.999488 470.263433 100.938412 59.190077 59.203892 100.92562 470.263945 512 59.232544 923.045727 100.968088 964.78127 511.999488 553.736567 923.057495 964.8089 964.794062 923.073356 553.732985 512Z"
              fill="#CCCCCC" p-id="4292" />
          </svg>
        </div>
      </ul>
    </div>
    <div class="top">
      <div class="tabulation" style="display: none;"  @click="toggleActive">
        <svg t="1718186797314" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="5022" width="30" height="30">
          <path
            d="M98.357122 818.73194c-14.213723 0-25.764803 11.532661-25.764803 25.76071 0 14.233166 11.55108 25.770943 25.764803 25.770943M922.866648 818.73194 98.128925 818.73194c-14.21884 0-25.770943 11.532661-25.770943 25.76071 0 14.233166 11.552104 25.770943 25.770943 25.770943l824.737724 0c14.213723 0 25.764803-11.538801 25.764803-25.770943C948.633498 830.262554 937.081395 818.73194 922.866648 818.73194zM98.357122 483.770052c-14.213723 0-25.764803 11.537777-25.764803 25.76992 0 14.229073 11.55108 25.764803 25.764803 25.764803M922.866648 483.770052 98.128925 483.770052c-14.21884 0-25.770943 11.537777-25.770943 25.76992 0 14.229073 11.552104 25.764803 25.770943 25.764803l824.737724 0c14.213723 0 25.764803-11.537777 25.764803-25.764803C948.633498 495.307829 937.081395 483.770052 922.866648 483.770052zM98.357122 148.815327c-14.213723 0-25.764803 11.539824-25.764803 25.768897 0 14.227026 11.55108 25.76378 25.764803 25.76378M98.128925 200.346981l824.737724 0c14.213723 0 25.764803-11.536754 25.764803-25.76378 0-14.229073-11.55108-25.768897-25.764803-25.768897L98.128925 148.814304c-14.21884 0-25.770943 11.539824-25.770943 25.768897C72.359005 188.81125 83.911108 200.346981 98.128925 200.346981z"
            fill="#2c2c2c" p-id="5023" />
        </svg>
      </div>
      <div class="wrap" style="position:relative;">
        <a href="/">
          <img src="@/assets/top_logo.png" class="logo1" />
        </a>
        <div class="search">
          <div class="searchbox">
            <div style="display:flex">
              <input placeholder="请输入关键字搜索" name="q" type="text" id="keyword" v-model="keyword" />
              <button @click="search(keyword)" type="submit"></button>
              <button @click="login" type="submit">登录</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="wrap">
        <ul class="navUl">
          <li class="fli">
            <router-link to="/">首页</router-link>
          </li>
          <li class="fli" @mouseleave="hideShow1()" @mouseover="isShow1();">
            <router-link :class="{on:showChildren_one}" :to="{path:'/about',query:{id:abouturl}}">协会概括</router-link>
            <ul class="ful" :style="{display:showChildren_one?'block':'none'}">
              <li v-for="(sonItem,index) in aboutList" :key="index">
                <router-link :to="{path:'/about',query:{id:sonItem.id}}">{{sonItem.title}}</router-link>
              </li>
            </ul>
          </li>
          <li class="fli" @mouseleave="hideShow2()" @mouseover="isShow2();">
            <router-link :class="{on:showChildren_two}" :to="{path:'/trends',query:{id:1}}">协会动态</router-link>
            <ul class="ful" :style="{display:showChildren_two?'block':'none'}">
              <li v-for="(sonItem,index) in newsList" :key="index">
                <router-link :to="{path:'/trends',query:{id:sonItem.id}}">{{sonItem.title}}</router-link>
              </li>
            </ul>
          </li>
          <li class="fli" v-for="(item,index) in topList" :key="index" @mouseleave="hideShow(item)"
            @mouseover="isShow(item);activeIndex=index">
            <router-link v-if="item.parent_id!==28&&item.parent_id!==9" :class="{on:activeIndex===index}"
              :to="{path:'/article',query:{id:item.children[0].children_id}}">{{item.parentName}}</router-link>
            <router-link v-if="item.parent_id===28" :class="{on:activeIndex===index}"
              :to="{path:'/trends/new?id=3'}">{{item.parentName}}</router-link>
            <!-- <router-link v-if="item.parent_id===9" :class="{on:activeIndex===index}"
              :to="{path:'/about/business?id=5'}">{{item.parentName}}</router-link> -->
            <ul class="ful" v-if="item.children[0].children_id!==null"
              :style="{display:item.showChildren?'block':'none'}">
              <li v-for="(sonItem,index) in item.children" :key="index">
                <router-link
                  :to="{path:'/article',query:{id:sonItem.children_id}}">{{sonItem.childrenName}}</router-link>
              </li>
            </ul>
          </li>
          <li class="fli">
            <router-link to="/trendsDetail?id=43">协会全媒体</router-link>
          </li>
          <li class="fli">
            <router-link to="/about/business?id=5">联系我们</router-link>
          </li>
          <li class="fli">
            <a href="http://www.szgh.cn/">数字公会</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        keyword: '',
        abouturl: 1,
        topList: [],
        isActive: false,
        isDelete: true,
        isSecActive: false,
        expandedUls: [],
        activeIndex: -1,
        activeIndex1: -1,
        showChildren_one: false,
        showChildren_two: false,
        aboutList: [],
        newsList: [],
        isshowChildren: true
      };
    },
    mounted() {
      this.getTop();
      this.getAbout();
      this.getNews();
    },
    methods: {
      isShow1() {
        this.showChildren_one = true;
      },
      hideShow1(item) {
        this.showChildren_one = false;
      },
      isShow2() {
        this.showChildren_two = true;
      },
      hideShow2(item) {
        this.showChildren_two = false;
      },
      isShow(item) {
        item.showChildren = true;
      },
      hideShow(item) {
        item.showChildren = false;
        this.activeIndex = -1;
      },
      async getTop() {
        const res = await this.$request.post("articlecategory/getPaginate", {
          fil: 3
        });
        if (res.data.code === 0) {
          this.topList = res.data.data;

          // 使用一个对象来存储中间结果
          const result = {};

          this.topList.forEach(item => {
            // 如果 parent_id 还不存在于 result 中，则初始化它
            if (!result[item.parent_id]) {
              result[item.parent_id] = {
                fa_show: item.fa_show,
                is_show: item.is_show,
                parentName: item.parentName,
                parent_id: item.parent_id,
                sort: item.parent_sort
              };
            }
            // 将当前对象添加到对应 parent_id 的 children 数组中
            result[item.parent_id].children =
              result[item.parent_id].children || [];
            result[item.parent_id].children.push({
              childrenName: item.childrenName,
              children_id: item.children_id
            });
          });

          // 将中间结果对象转换为数组
          this.topList = Object.values(result);
          this.topList = this.sortArr(this.topList)
         
        }
      },
      async getAbout() {
        const res = await this.$request.get("about/getPaginate");
        if (res.data.code === 0) {
          this.aboutList = res.data.data.data;
        }
      },
      async getNews() {
        const res = await this.$request.post("newscategory/getPaginate", {
          fil: 1
        });
        if (res.data.code === 0) {
          this.newsList = res.data.data;
        }
      },
      expandTab(event) {
        // 阻止事件冒泡到其他元素
        event.stopPropagation();

        // 获取当前点击的元素
        const target = event.target;

        // 递归查找最近的li父元素
        function findClosestLiParent(element) {
          if (element.tagName === 'LI') {
            return element;
          } else if (element.parentElement) {
            return findClosestLiParent(element.parentElement);
          } else {
            return null;
          }
        }

        // 找到最近的li父元素
        const liElement = findClosestLiParent(target);

        // 如果找到的li父元素存在且包含nav_menu-item类名
        if (liElement && liElement.classList.contains("nav_menu-item")) {
          // 获取该li元素下的ul元素
          const ulElement = liElement.querySelector('ul');
          if (ulElement) {
            // 检查当前ul元素是否已经在expandedUls数组中
            const index = this.expandedUls.indexOf(ulElement);
            if (index !== -1) {
              // 如果在数组中，移除它，表示收起列表
              this.expandedUls.splice(index, 1);
              ulElement.classList.remove('expand_sec');
            } else {
              // 如果不在数组中，添加它，表示展开列表
              // 同时，移除其他已展开的列表
              this.expandedUls.forEach(ul => ul.classList.remove('expand_sec'));
              this.expandedUls = [ulElement];
              ulElement.classList.add('expand_sec');
            }
          }
        }
      },
      toggleActive() {
        this.isActive = !this.isActive;
        this.isDelete = !this.isDelete;
      },
      //搜索功能
      search(keyword) {
        this.$router.push({
          path: "/search",
          query: {
            name: keyword
          }
        })
      },
      //登录跳转
      login() {
        window.location.href = "http://saas.szgh.cn/"
      },
      // 排序方法
      sortArr(array) {
        return array.sort((a, b) => {
          // 如果 a.sort 或 b.sort 是 undefined，将其视为最小值
          const sortA = a.sort === undefined ? -Infinity : a.sort;
          const sortB = b.sort === undefined ? -Infinity : b.sort;
          return sortA - sortB;
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  a{
    color: white;
  }
  .searchbox {
    width: 378px;
  }

  .expand_sec{
  display: block !important;
}
.dele{
  visibility: hidden;
}

  .searchbox button:last-child {
    background: none;
    width: 69px;
    margin-left: 20px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #606266;
    background: #ecf5ff
  }
  .searchbox button:last-child:hover{
    background-color: #66a6ff;
    color: white;
  }

  .header .nav .fli>a{
    font-size: 18px;
    padding: 0 24px;
  }

  .header .nav .fli .ful li {
    background-color: #2688ff;
  }

  @media screen and (max-width:768px) {
    .header {
      width: 100%;
    }

    .header .top {
      width: 100vw;
      min-width: 0;
      display: flex;
      align-items: center;
    }

    .header .top .logo1 {
      margin: 0 auto;
    }

    .wrap a {
      display: flex;
    }

    .wrap .searchbox {
      display: none;
    }
  }

  .on {
    background-color: #66a6ff;
    color: white;
  }

  a:hover {
    background-color: #66a6ff;
    color: white !important;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .header {
      height: 80px;
    }

    .header .move-tab {
      display: block !important;
      position: relative;
      width: 100%;
    }

    .header .move-tab .nav_menu {
      width: 15rem;
      position: -webkit-sticky;
      position: fixed;
      z-index: 999;
      display: block;
      left: 0;
      height: 100vh;
      transition: all 0.5s ease;
      background-color: #333;
      transform: translateX(-100%);
    }

    .header .move-tab .nav_menu .nav_menu-item {
      position: relative;
      width: 100%;
      line-height: 65px;
      border-bottom: 1px solid #666;
    }

    .header .move-tab .nav_menu .nav_menu-item .active {
      display: block;
      max-height: 100%;
    }

    .header .move-tab .nav_menu .nav_menu-item .icon-expand {
      position: absolute;
      right: 20px;
      top: 5px;
    }

    .header .move-tab .nav_menu .nav_menu-item a {
      margin-left: 50px;
      font-size: 15px;
      color: white;
    }

    .header .move-tab .nav_menu .nav_menu-item .second-tab {
      display: none;
    }

    .header .move-tab .nav_menu #toggleMenu {
      position: absolute;
      right: -40px;
      top: 23px;
    }

    .header .move-tab .expand {
      transform: translateX(0);
    }

    .header .container {
      justify-content: space-around;
      width: 100vw;
      background: #fff;
      padding: 0 0.5rem;
    }

    .top .tabulation {
      display: block !important;
    }

    .header .container .l {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    .header .container .l .logo {
      width: 106px;
      margin-right: 0px;
      transition: all 0.5s ease;
    }

    .header .container .l .company-name {
      font-size: 4vw;
      transition: all 0.5s ease;
    }

    .header .container .l .company-name .company-name-en {
      font-size: 2vw;
    }

    .header .container .l .hide-title {
      transform: translateX(350%);
    }

    .header .container .r {
      display: none;
    }
    
  }
</style>