<template lang="">
    <div>
        <div class="fix" :class="{isClose:isclose}">

            <ul>
                <li>公众号<img src="/public/er1.jpg"></li>
                <li>数字公会<img src="/public/er2.jpg"></li>
            </ul>
            <span @click="isClose">x 关闭</span>
        </div>
        <div class="cont">
            <div class="banner" style="height: auto;">
                <swiper :options="swiperOptions">
                    <swiper-slide class="li" style=" height: auto;" v-for="(item,index) in bannerList"
                        :key="index">
                        <img :src="item.picture">
                        <!-- </a> -->
                    </swiper-slide>
                </swiper>
                <div class="dot" style="width: 256px; ">
                    <span v-for="(item,index) in bannerList" :key="index" :class="{ cur: isActive(index) }"></span>
                </div>
            </div>
        </div>
        <div class="news">
            <div class="wrap">
                <!--推荐新闻-->
                <div class="cell cell1" style="height: 400px; margin-bottom:0">
                    <!-- slider -->
                    <link href="/public/responsiveslides.css" rel="stylesheet">
                    <div class="callbacks_container">
                        <swiper :options="leftSwiper" class="rslides callbacks callbacks1" id="slider4">
                            <swiper-slide id="callbacks1_s0" class="" v-for="(item,index) in newsList.slice(0,5)"
                                :key="index">
                                <img :src="item.image_url" alt="">
                                <p class="caption">
                                    <router-link
                                        :to="{path:'/trendsDetail',query:{id:item.id}}">{{$deTag(item.description)}}
                                    </router-link>
                                </p>
                            </swiper-slide>
                        </swiper>
                        <a href="" class="callbacks_nav callbacks1_nav prev">Previous</a>
                        <a href="" class="callbacks_nav callbacks1_nav next">Next</a>
                    </div>
                    <!--end slider -->
                </div>
                <!--协会动态-->
                <div class="cell cell2">

                    <div class="top click">
                        <span v-for="(item,index) in category" :key="index" @mouseover="activeTab=index"
                            :class="{on:activeTab==index}">
                            <router-link to="">{{item.name}}</router-link>
                        </span>

                        <router-link href="" id="detail01">MORE &gt;</router-link>
                    </div>
                    <!--新闻列表-->
                    <div class="middle list-campus">
                        <ul id="detail03" :class="{isShow:activeTab==index}" v-for="(item,index) in category"
                            :key="index">
                            <li class="item3" v-for="(sonItem,sonIndex) in item.content.slice(0,6)" :key="sonIndex">
                                <div class="date">
                                    <div class="d">{{sonItem.month}}</div>
                                    <div class="m">{{sonItem.day}}月</div>
                                </div>
                                <div class="title">
                                    <router-link
                                        :to="{path:'/trendsDetail',query:{id:sonItem.id}}">{{sonItem.title}}</router-link>
                                </div>
                                <div class="clear"></div>
                            </li>
                        </ul>
                    </div>

                    <div class="clear"></div>

                </div>
                <div class="ilists" v-if="bossList">
                    <p class="ptitles"><span>会员风采</span><router-link class="mores"
                            :to="{path:'/article',query:{id:bossList[0].category_sec_id}}">MORE &gt;</router-link>
                    </p>
                    <router-link v-if="bossList" class="in"
                    :to="{path:'/articleDetail',query:{id:bossList[0].id,category_sec_id:bossList[0].category_sec_id}}">
                    <img :src="bossList[0].image_url" class="swiper-image">
                    <p class="p1 swiper-text">
                      <span>{{bossList[0].name}}</span>
                      <span>{{bossList[0].ArticleCategory.name}}</span>
                      <span>{{$deTag(bossList[0].description)}}</span>
                    </p>
                   </router-link>
                    <!-- <swiper :options="memberSwiper">
                        <swiper-slide class="in" v-for="(item,index) in bossList" :key="index">
                            <router-link
                                :to="{path:'/articleDetail',query:{id:item.id,category_sec_id:item.category_sec_id}}">
                                <img :src="item.image_url" class="swiper-image">
                                <p class="p1 swiper-text">
                                  <span>{{item.name}}</span>
                                  <span>{{item.ArticleCategory.name}}</span>
                                  <span>{{$deTag(item.description)}}</span>
                                </p>
                            </router-link>
                        </swiper-slide>
                    </swiper> -->
                </div>
             
            </div>
            <div style="clear: both;display: block"></div>
        </div>
        <div class="strategy">
            <p class="ptitles"><span>非公党建</span><router-link class="mores"
                    :to="{path:'/trends/new?id=3'}">MORE &gt;</router-link></p>
            <nav class="building">
                <router-link v-for="(item,index) in buildingList.slice(0,4)" :key="index" class="build"
                    :to="{path:'/trendsDetail',query:{id:item.id}}"><img
                        :src="item.image_url"><span>{{item.title}}</span>
                </router-link>
            </nav>
        </div>
        <div class="strategy">
            <p class="ptitles"><span>战略合作</span><router-link class="mores" 
                    :to="{path:'/article',query:{id:cooperList[0].category_sec_id}}">MORE &gt;</router-link></p>
            <nav class="cooper">
                <router-link v-for="(item,index) in partnerList" :key="index" 
                    :to="{path:'/articleDetail',query:{id:item.id,category_sec_id:item.category_sec_id}}"><img
                        :src="item.image_url"><span>{{item.name}}</span>
                </router-link>
            </nav>
        </div>
        <div class="links">
            <p class="ptitles"><span>友情链接</span><!--<a class="mores" href="#">MORE ></a>--></p>
            <nav>
                <a v-for="(item,index) in linkList" :href="item.link" target="_blank">{{item.name}}</a>&nbsp;
            </nav>
        </div>
    </div>

</template>
<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      bannerList: [],
      newsList: [],
      articleList: [],
      bossList: [],
      charityList: [],
      cooperList: [],
      buildingList: [],
      memberList: [],
      businessList: [],
      partnerList: [],
      linkList: [],
      category: [],
      isclose: false,
      activeTab: 0,
      swiperOptions: {
        loop: true,
        autoHeight: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false
        },
        pagination: {
          el: ".dot",
          clickable: true
        }
      },
      leftSwiper: {
        loop: true,
        autoHeight: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".next ", // 下一个按钮的选择器
          prevEl: ".prev" // 上一个按钮的选择器
          // clickable: true,
        }
      },
     memberSwiper: {
        //  loop: true,
        autoHeight: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false
        },
        
        slidesPerView: 5,
        spaceBetween: 10,
        direction: 'horizontal',
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        // 响应式
        breakpoints:{
          768:{
            slidesPerView:1,
            spaceBetween: 10,
          }
        }
      },
      currentSlideIndex: 0
    };
  },
  mounted() {
    this.getBanner();
    this.getNews();
    this.getArticle();
    this.getLink();
  },
  methods: {
    async getBanner() {
      let res = await this.$request.post("homebanner/getPaginate");
      if (res.data.code === 0) {
        this.bannerList = res.data.data.data;
      }
    },
    async getNews() {
      let res = await this.$request.post("news/getPaginate", {
        fil: 2
      });
      if (res.data.code === 0) {
        this.newsList = res.data.data;
        this.newsList = this.newsList.filter(
          item => item.category.is_show === 1
        );
        this.buildingList = this.newsList.filter(
          item => item.category_id === 3 && item.is_show == 1
        );
        this.newsList = this.newsList.filter(item => item.is_hot === 1);
        this.newsList.forEach(item => {
          item.create_time = item.create_time.split(" ");
          item.create_time[0] = item.create_time[0].split("-");
          item.month = item.create_time[0][1];
          item.day = item.create_time[0][2];
        });
        this.category = this.changeNews(this.newsList);
      }
    },
    //转换资讯格式
    changeNews(arr) {
      const grouped = arr.reduce((groups, item) => {
        if (!groups[item.category_id]) {
          groups[item.category_id] = {
            name: item.category_title,
            content: []
          };
        }
        groups[item.category_id].content.push(item);
        return groups;
      }, {});

      //将分组后的对象数组化
      const result = Object.values(grouped);
      return result;
    },
    onSlideChange(swiper) {
      this.currentSlideIndex = swiper.realIndex;
    },
    isActive(index) {
      return index === this.currentSlideIndex;
    },
    isClose() {
      this.isclose = !this.isclose;
    },
    async getArticle() {
      const res = await this.$request.post("article/getAll");
      if (res.data.code === 0) {
        this.articleList = res.data.data;
        this.bossList = this.articleList.filter(
          item => item.category_id === 2 && item.is_hot === 1
        );
        console.log(this.bossList,'我的boss');
        this.memberList = this.articleList.filter(
          item => item.category_id === 2 && item.is_hot === 1
        );
        // this.charityList = this.articleList.filter(item => item.category_id === 30 && item.is_hot === 1)
        this.cooperList = this.articleList.filter(
          item => item.category_id === 24 && item.is_hot === 1
        );
        this.partnerList = this.articleList.filter(
          item =>
            item.category_id === 24 &&
            item.category_sec_id === 25 &&
            item.is_hot === 1
        );
      }
    },
    async getLink() {
      let res = await this.$request.post("friendlink/getAll");
      if (res.data.code === 0) {
        this.linkList = res.data.data;
      }
    }
  }
};
</script>
<style lang="scss" scoped> 

  a{
    font-size: 16px ;
  }
  p{
    font-size: 16px !important;
  }

  span{
    font-size: 16px ;
  }

  .cont{
   width: 100%;
  }
  .cont .banner{
   width: 100%;
  }
  .cont .banner .dot{
    width: 256px;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translate(-50%, -50%);
  }
  .banner img{
    width: 100%;
  }
  .strategy .building{
    display: flex;
    justify-content:space-between;
  }
/* 
  .p1{
    display: flex;
    flex-direction: column;
  } */
  .p1 span:nth-child(1){
    color: black;
  }
  .p1 span:nth-child(2){
    font-size: 14px;
  }
  .p1 span:nth-child(3){
    font-size: 14px;
  }
  .p1 span{
    line-height: 20px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 0 10px;
  }

  /* .strategy nav .build{
    margin-right: 2vw;
  }*/
  .strategy nav .build:last-child{
    margin-right: 0;
  } 
  
  .ilists .swiper-slide {
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.swiper-image {
  width: 300px;
  height: auto;
}

.swiper-pagination-bullet-active {
  background-color: white;
}

.strategy{
  margin: 0 auto 30px;
}

.strategy nav{
  padding: 35px 0;
}

.strategy nav .build {
    width: 280px;
    height: 200px;
}

.strategy nav a{
  margin-right: 13px;
}

.strategy nav a span {
  width: 165px;
  font-size: 16px;
}

.news{
  margin-bottom: 0;
}

.news .cell2 .top span{
  font-size: 18px;
}
.links nav a{
  font-size: 16px;
  margin-right: 10px;
}

.strategy nav .build img {
  width: 100%;
  height: 100%;
}

.strategy nav .build span {
  width: 250px;
  font-size: 16px;
  padding: 5px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy .cooper{
  padding-bottom: 0;
}

.strategy .cooper a{
  margin-right: 36px;
}

.strategy .cooper a:nth-child(6){
  margin-right: 0;
}
.strategy .cooper a:last-child{
  margin-right: 0;
}

.news .cell .middle ul {
  display: none;
}

.news .cell .middle .isShow {
  display: block;
}

.news .cell2 .top span a{
  font-size: 20px;
}

.news .cell .bottom p {
  color: #666;
}

.news .cell .bottom p:hover {
  color: #2697fa;
}
.links{
  margin-top:0;
}
.links nav{
  display: flex;
  flex-wrap: wrap;
  padding: 35px 0;
}
.links nav a{
  width: 12%;
  margin-right: 6px;
}
.links nav a:nth-child(8){
  margin-right: 0;
}
.links nav a:last-child{
  margin-right: 0;
}

.ilists .out {
  overflow: hidden;
  padding: 20px;
  margin-top: -2px;
  border: 1px solid #dbdbdb;
  border-top: 1px solid #e73f3f;
}

.ilists .in {
  float: left;
  width: 184px;
  margin-right: 10px;
}

.ilists .in a{
  width: 100%;
}

.ilists .in img {
  width: 100%;
  height: 230px;
  /* max-height: 230px; */
  display: block;
}

.ilists .in p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  text-align: center;
  font-size: 14px;
  line-height: 42px;
  padding: 5px 0 5px;
  background: #ededed;
}

.isClose {
  display: none !important;
}

.callbacks .caption a {
  font-size: 16px;
  height: 45px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mores {
  font-size: 16px;
}

.fix {
  color: #333;
  left: 56%;
  margin: 0 0 0 505px;
  overflow: hidden;
  position: fixed;
  top: 332px;
  width: 110px;
  z-index: 10001;
}

.fix li {
  background: #ebebeb;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
}

.fix li img {
  width: 100px;
  border: 1px solid black;
}

.fix li:hover {
  background: #d1d1d1;
}

.list-campus ul li .title a{
  font-size: 16px;
}

.fix span {
  text-align: right;
  display: block;
  padding: 10px 0;
  font-size: 16px;
}

.ptitles span {
  font-size: 20px !important ;
}
@media screen and (max-width:768px) {
  .strategy .building{
    flex-direction: column;
  }
  .strategy .build {
    width: 100% !important;
    height: 100% !important;
  }
  .strategy .build span{
    width: 85vw !important;
  }
  .strategy .cooper a{
    margin-right: 0;
  }
  .strategy .cooper a span{
    width: 100%;
  }
  .links nav a{
    width: 47%;
  }
  .banner .dot{
    display: none;
  }
}
</style>