import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    redirect: '/about/business',
    component: () => import('@/views/about'),
    children: [
      {
        path: 'business',
        name: 'business',
        meta: '协会简介',
        component: () => import('@/views/about/business')
      },
    ]
  },
  {
    path: '/trends',
    name: 'trends',
    redirect: '/trends/new',
    component: () => import('@/views/trends'),
    children: [
      {
        path: 'new',
        name: 'new',
        meta: '协会动态',
        component: () => import('@/views/trends/new')
      },
      // {
      //   path: 'activity',
      //   name: 'activity',
      //   meta: '商会活动',
      //   component: () => import('@/views/trends/activity')
      // },
      // {
      //   path: 'building',
      //   name: 'building',
      //   meta: '商会党建',
      //   component: () => import('@/views/trends/building')
      // },
      // {
      //   path: 'membernews',
      //   name: 'membernews',
      //   meta: '会员新闻',
      //   component: () => import('@/views/trends/membernews')
      // },
      // {
      //   path: 'specialnews',
      //   name: 'specialnews',
      //   meta: '专委会新闻',
      //   component: () => import('@/views/trends/specialnews')
      // }
    ]
  },

  {
    path: '/article',
    name: 'article',
    // redirect: '/technology/r&d',
    component: () => import('@/views/article'),
  },
  {
    path: '/trendsDetail',
    name: 'trendsDetail',
    // redirect: '/technology/r&d',
    component: () => import('@/views/trends/detail'),
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    // redirect: '/technology/r&d',
    component: () => import('@/views/article/detail'),
  },
  // {
  //   path: '/member',
  //   name: 'member',
  //   component:() => import('@/views/member')
  // },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import('@/views/cooperate'),
    children: [
      {
        path: 'cooperate',
        name: 'cooperate',
        meta: '战略合作',
        component: () => import('@/views/cooperate/cooperate')
      },
      {
        path: 'cooperatenews',
        name: 'cooperatenews',
        meta: '商务合作资讯',
        component: () => import('@/views/cooperate/cooperatenews')
      },
      {
        path: 'service',
        name: 'service',
        meta: '经促服务站',
        component: () => import('@/views/cooperate/service')
      },
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    //x代表横坐标，y代表纵坐标
    return {x:0,y:0};
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.name === 'productDetail' && !to.params.id) {
//     next({ name: 'ExampleProductDetail' }); // 重定向到示例商品详情页
//   } else {
//     next(); // 继续导航
//   }
// });

export default router
